<template>
    <div class="container-div">
        <div class="table-div">
            <div class="input-div">
                <Button style="margin-left: 10px" type="primary" @click="chooseCustom">选择管理员</Button>
                <Button style="margin-left: 10px" type="primary" @click="onDisplayGroupMember">选择群成员</Button>
                <Input v-model="searchPhone" placeholder="输入手机号搜索" style="width:200px;margin:0 20px" />
                <Button type="primary" @click="addPerson">添加用户</Button>
            </div>
            <div class="content-div">
                <div class="title-div">
                    <div>已选人员管理</div>
                    <div>
                        <span style="margin-right: 10px">部分删除可点击姓名选中后点击批量删除</span>
                        <Button type="primary" style="margin-right: 10px" @click="deleteOther">批量删除</Button>
                        <Button type="primary" @click="deleteAll">清空全部</Button>
                    </div>
                </div>
                <div class="select-div">
                    <div class="select-content">
                        <div :class="item.active ? 'item active' : 'item'" v-for="(item, index) in tableData" :key="index" @click="changeItem(item, index)">
                            {{ item.name || "暂无" }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <LiefengModal title="选择管理员" :value="personStatus" @input="personStatusStatusFn" :fullscreen="false">
            <template v-slot:contentarea>
                <div class="custom-div">
                    <div class="search-div">
                        <Input placeholder="请输入姓名搜索" v-model="searchName" />
                        <Button type="primary" style="margin-left:10px" @click="searchBtn">查询</Button>
                        <Button type="primary" style="margin-left:10px" @click="resetBtn">重置</Button>
                    </div>
                    <div class="list">
                        <div v-for="(item, index) in customList" :key="index">
                            <Checkbox
                                v-model="item.checked"
                                @on-change="
                                    val => {
                                        changecheck(val, index)
                                    }
                                "
                            >
                                {{ item.name }}
                            </Checkbox>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="personStatusStatusFn(false)" style="margin-right: 10px">取消</Button>
                <Button type="primary" @click="saveSynchroniZation">确定</Button>
            </template>
        </LiefengModal>

        <CompModal ref="comp_modal" title="选择群成员" @on-scroll-bottom="onLoadGroups" width="500px" :top-level="true">
            <div class="tree-box" style="margin:0 10px">
                <Tree ref="group_tree" :data="groups" :load-data="onLoadGroupMember" show-checkbox></Tree>
            </div>
            <template #operate="evt">
                <div class="operate-btns">
                    <div class="operate-btn highlight" @click="onSubmitGroupMember">提交</div>
                    <div class="operate-btn" @click="evt.close">取消</div>
                </div>
            </template>
        </CompModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"

import CompModal from "@/views/residentdatabase/components/CompModal"
import RequestPage from "@/views/jointly/utils/page"

export default {
    props: {
        selectList: {
            type: Array,
        },
        infoId: {
            type: String,
        },
    },
    data() {
        //这里存放数据
        return {
            tableData: [],
            personStatus: false,
            customList: [],
            searchName: "",
            searchPhone: "",

            // 群成员群组
            groups: [],
        }
    },
    async created() {
        await this.getCustomList()
    },
    //方法集合
    methods: {
        onDisplayGroupMember() {
            this.$refs.comp_modal.display()

            if (!this.isLoadGroup) {
                this.getGroups()
            }
        },

        // 获取沟通组列表
        getGroups() {
            this.reqGroupsPage = new RequestPage("/sendmsg/pc/chat/groupCreate/queryPage", {
                data: {
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                },
                size: 40,
                type: "block",
                onLoadBefore: () => {
                    this.$Message.loading({
                        content: "加载中...",
                        duration: 0,
                    })
                },
                onLoadAfter: () => {
                    this.$Message.destroy()
                },
                onChange: res => {
                    const groups = this.groups || []

                    for (let i = 0; i < res.length; i++) {
                        let v = res[i]

                        groups.push({
                            id: v.groupId,
                            title: v.groupName,
                            loading: false,
                            children: [],
                            type: "group",
                        })
                    }

                    this.groups = groups
                },
            })

            this.isLoadGroup = true
        },

        onLoadGroups() {
            this.reqGroupsPage.load()
        },

        /** 加载群成员 */
        async onLoadGroupMember(item, cb) {
            this.$Message.loading("加载中...")

            const getAllMember = async (list = [], page = 1) => {
                return new Promise(resolve => {
                    this.$get(`/sendmsg/pc/chat/groupCreate/queryMemberPage?account=&joinType=&name=&page=${page}&pageSize=20&labelCode=&hasSensitive=true`, {
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        groupId: item.id,
                    }).then(async res => {
                        if (res.code != 200) {
                            return this.$Message.error(res.desc)
                        }

                        for (let i = 0, l = res.dataList; i < l.length; i++) {
                            let v = l[i]

                            list.push({
                                id: v.custGlobalId,
                                title: v.name,
                                type: "member",
                            })
                        }

                        if (res.currentPage < res.maxPage) {
                            await getAllMember(list, ++page)
                        }

                        resolve(list)
                    })
                })
            }

            const mbs = await getAllMember()

            cb(mbs)

            this.$Message.destroy()

            if (mbs.length <= 0) {
                this.$Message.warning("当前沟通组暂无成员")
            }
        },

        /** 提交选择群用户 */
        onSubmitGroupMember() {
            const s = []

            for (let i = 0, l = this.$refs.group_tree.getCheckedNodes(); i < l.length; i++) {
                let v = l[i]

                // 勾选框为沟通组或成员为已选侧跳过
                if (v.type === "group" || this.isSelected(v.id)) {
                    continue
                }

                s.push({
                    name: v.title,
                    custGlobalId: v.id,
                })
            }

            this.$set(this, "tableData", this.tableData.concat(s))

            // 关闭弹窗
            this.$refs.comp_modal.close()
        },

        /** 校验是否已选 */
        isSelected(custGlobalId) {
            for (let i = 0, l = this.tableData; i < l.length; i++) {
                if (l[i].custGlobalId === custGlobalId) {
                    return true
                }
            }
            return false
        },

        // 点击添加按钮
        addPerson() {
            if (this.searchPhone && this.searchPhone != "") {
                this.$get("/gateway/apps/basic/api/base/uc/getByMobileOrNickname", {
                    mobileOrNickname: this.searchPhone,
                }).then(res => {
                    if (res.code && res.code == 200) {
                        if (!res.dataList.length) {
                            this.$Message.error({
                                content: "添加失败，找不到对应用户",
                                background: true,
                            })
                            return
                        }
                        res.dataList.map(item => {
                            this.tableData.push({
                                ...item,
                                name: item.realName,
                                active: false,
                                checked: false,
                            })
                        })
                        this.$nextTick(() => {
                            this.tableData = this.$core.onlyList(this.tableData, "custGlobalId")
                        })
                    }
                })
            }
        },
        // 重置按钮
        resetBtn() {
            this.searchName = ""
            this.searchBtn()
        },
        // 查询按钮
        searchBtn() {
            if (this.searchName != "") {
                this.getCustomList(this.searchName)
            } else {
                this.getCustomList()
            }
        },
        // 复选框改变事件
        changecheck(val, index) {
            this.customList[index].checked = val
        },
        // 关闭选择管理员弹窗
        async personStatusStatusFn(status) {
            this.personStatus = status
        },
        // 确定按钮
        saveSynchroniZation() {
            if (this.customList.length) {
                this.customList.map(item => {
                    if (item.checked)
                        this.tableData.push({
                            ...item,
                            active: false,
                        })
                })
            }

            if (this.customList.length) {
                let list = []
                this.customList.map(item => {
                    if (!item.checked) list.push(item.custGlobalId)
                })
                let ids = list.join(",")
                var i = this.tableData.length
                while (i--) {
                    if (ids.indexOf(this.tableData[i].custGlobalId) > -1) {
                        this.tableData.splice(i, 1)
                    }
                }
            }
            this.$nextTick(() => {
                this.tableData = this.$core.onlyList(this.tableData, "custGlobalId")
                this.personStatusStatusFn(false)
            })
            this.personStatusStatusFn(false)
        },
        // 选择管理员按钮
        chooseCustom() {
            if (this.tableData.length) {
                let ids = []
                this.tableData.map(item => {
                    ids.push(item.custGlobalId)
                })
                if (this.customList.length) {
                    let idsList = ids.join(",")
                    this.customList.map(item => {
                        if (idsList.indexOf(item.custGlobalId) > -1) {
                            item.checked = true
                        } else {
                            item.checked = false
                        }
                    })
                }
            } else {
                this.customList.map(item => {
                    item.checked = false
                    item.active = false
                })
            }
            this.personStatusStatusFn(true)
        },
        // 选择人员
        changeItem(item, index) {
            this.$set(this.tableData[index], "active", !this.tableData[index].active)
        },
        // 选择批量删除
        deleteOther() {
            let list = []
            this.tableData.map(item => {
                if (item.active) list.push(item.custGlobalId)
            })
            if (!list.length) {
                this.$Message.warning({
                    content: "请选择需要删除的人员",
                    background: true,
                })
                return
            } else {
                let ids = list.join(",")
                var i = this.tableData.length
                while (i--) {
                    if (ids.indexOf(this.tableData[i].custGlobalId) > -1) {
                        this.tableData.splice(i, 1)
                    }
                }
            }
        },
        deleteAll() {
            this.tableData = []
        },

        // 获取管理员接口
        async getCustomList(name) {
            this.$Message.loading({
                content: "正在加载，请稍等",
            })
            await this.$get("/old/api/pc/staff/selectBaseManagerStaffList", {
                userType: "09",
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                staffId: parent.vue.loginInfo.userinfo.id,
            }).then(res => {
                this.customList = []
                this.$Message.destroy()
                if (res.code == 200) {
                    if (name && name != "") {
                        let data = []
                        res.dataList.map(item => {
                            if (item.name.search(name) > -1) {
                                data.push(item)
                            }
                        })
                        res.dataList = JSON.parse(JSON.stringify(data))
                    }
                    if (this.tableData && this.tableData.length) {
                        let ids = []
                        this.tableData.map(item => {
                            ids.push(item.custGlobalId)
                        })
                        if (res.dataList.length) {
                            let idsList = ids.join(",")
                            this.customList = res.dataList.map(item => {
                                if (idsList.indexOf(item.custGlobalId) > -1) {
                                    return {
                                        ...item,
                                        checked: true,
                                        active: false,
                                    }
                                } else {
                                    return {
                                        ...item,
                                        checked: false,
                                        active: false,
                                    }
                                }
                            })
                        }
                    } else {
                        this.customList = res.dataList.map(item => {
                            return {
                                ...item,
                                checked: false,
                                active: false,
                            }
                        })
                    }
                }
            })
        },
        async save() {
            // if(!this.tableData || !this.tableData.length){
            //     this.$Message.error({
            //         content:'请选择指定人员',
            //         background:true
            //     })
            //     return false
            // }
            let list = []
            this.tableData.map(item => {
                list.push({
                    userName: item.name,
                    custGlobalId: item.custGlobalId,
                })
            })

            await this.$post(
                "/info/api/pc/information/member/saveMember",
                {
                    informationId: this.infoId,
                    dataList: list,
                },
                {
                    "Content-Type": "Application/json",
                }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "保存成功",
                        background: true,
                    })
                    return true
                } else {
                    this.$Message.error({
                        content: "保存失败",
                        background: true,
                    })
                    return false
                }
            })
        },
    },
    watch: {
        selectList: {
            handler(val) {
                console.log(val)
                this.tableData = val
            },
            deep: true,
        },
    },
    components: {
        LiefengModal,
        CompModal,
    },
}
</script>
<style lang="less" scoped>
//编写css
.container-div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    //   height: 750px;
    .table-div {
        width: 100%;
        .input-div {
            margin: 10px 0;
            display: flex;
        }
        .content-div {
            height: 660px;
            width: 100%;
            border: 1px solid #ccc;
            .title-div {
                display: flex;
                justify-content: space-between;
                height: 40px;
                line-height: 40px;
                border-bottom: 1px solid #ccc;
                padding: 0 20px;
            }
            .select-div {
                overflow-y: scroll;
                padding: 20px;
                height: 600px;
                .select-content {
                    display: flex;
                    flex-wrap: wrap;
                    .item {
                        width: 15%;
                        border: 1px solid rgba(#ccc, 0.5);
                        margin: 10px;
                        height: 40px;
                        line-height: 40px;
                        color: #333;
                        border-radius: 5px;
                        padding: 0 10px;
                        cursor: pointer;
                        user-select: none;
                    }
                    .active {
                        background: rgba(#2d8cf0, 0.5);
                        color: #fff;
                    }
                }
            }
        }
    }
}
.custom-div {
    .search-div {
        display: flex;
    }
    .list {
        margin-top: 10px;
        height: 400px;
        overflow: scroll;
        border: 1px solid #ccc;
        padding: 10px;
    }
}
</style>
