import * as request from "@/api/index"
import { Message } from "view-design"
import Loading from "./loading"

export default class Request {
    static get(url, data = {}, config = {}) {
        return this.__req("get", url, data, config)
    }

    static post(url, data = {}, config = {}) {
        return this.__req("post", url, data, config)
    }

    /**
     * 获取词典
     * @param {String} name 词典名称
     */
    static dict(name) {
        return this.__req("get", "/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
            dictType: name,
            appCode: "",
        })
    }

    static __req(method, url, data = {}, config = {}) {
        Loading.show()

        return new Promise((resolve, reject) => {
            request[method](url, data, {
                ...config.headers,
                ...(method === "post" ? { "Content-Type": config.json ? "application/json" : "application/x-www-form-urlencoded" } : {}),
            })
                .then(res => {
                    if (config.response?.(res) || res.code == 200) {
                        return setTimeout(() => {
                            // 显示成功提示
                            if (config.success) Message.success(config.success)
                            // 返回数据
                            resolve(config.full ? res : res.data ?? res.dataList)
                        })
                    }
                    this.__fail(res, config, reject)
                })
                .catch(err => {
                    this.__fail(err, config, reject)
                })
                .finally(() => Loading.hide())
        })
    }

    /**
     * 处理失败
     */
    static __fail(res, config, reject) {
        let msg = res.desc || config.fail || "请求失败，请稍后重试！"
        // 触发监听失败事件
        if (config.onFail?.(msg) !== false) {
            // 异常信息最长显示 50 个字符
            Message.error(msg.slice(0, 50))
        }
        // 返回异常信息
        reject(msg)
    }
}
