<template>
    <div class="comp-modal-page" :id="id" :class="{ full: isFull, scroll: isScroll }" :style="{ 'z-index': topLevel ? '2000' : '120' }" ref="page_box">
        <div class="content-page" :style="{ width: width, 'max-width': maxWidth, height: height }" @click.stop>
            <div class="head-box">
                <div class="title">
                    <slot name="title"></slot>
                    <span>{{ title }}</span>
                </div>
                <div class="head-operate">
                    <slot name="head-operate"></slot>
                </div>
                <div class="close-btn" @click="close">
                    <Icon type="md-close" />
                </div>
            </div>
            <div class="scroll-content" ref="content" @scroll="onScroll">
                <iframe v-if="iframeUrl" :src="iframeUrl" width="100%" height="100%" frameborder="0" style="display: block;"></iframe>
                <slot v-else :close="close"></slot>
            </div>
            <div class="bottom-operate" ref="bottom_operate">
                <div class="operate-btns" v-if="onSuccess || onClose">
                    <div class="operate-btn" @click=";(onClose ? onClose() !== false : true) && close()">取消</div>
                    <div class="operate-btn highlight" @click="onSuccess && onSuccess()">提交</div>
                </div>
                <slot name="operate" v-else :close="close"></slot>
            </div>
        </div>
    </div>
</template>

<script>
const COMP_MODAL_WINDOWS = (window["COMP_MODAL_WINDOWS"] = [])

export default {
    name: "CompModal",

    data() {
        return {
            id: this.$core.randomString(),
            status: false,
            // 加载池
            loadPool: 0,
            iframeUrl: null,
        }
    },

    props: {
        title: String,

        width: {
            type: String,
            default: null,
        },

        height: {
            type: String,
            default: "100%",
        },

        maxWidth: {
            type: String,
            default: "1920px",
        },
        // 持续显示，加载即显示
        continued: {
            type: Boolean,
            default: false,
        },
        // 全屏显示
        isFull: {
            type: Boolean,
            default: false,
        },
        // 最高层级
        topLevel: {
            type: Boolean,
            default: false,
        },
        // 监听关闭
        onClose: {
            type: Function,
            required: false,
        },
        // 监听提交按钮
        onSuccess: {
            type: Function,
            required: false,
        },
        // 可否滚动
        isScroll: {
            type: Boolean,
            default: true,
        },
    },

    watch: {
        status(v) {
            if (!v) {
                this.iframeUrl = null
            }
        },
    },

    created() {
        this.winow_config = {
            v: this,
            id: this.id,
            status: false,
        }

        Object.defineProperty(this.winow_config, "status", {
            set: v => {
                // let page = document.body.querySelector(".page")
                let e = document.getElementById(this.id)

                if (v) {
                    e.classList.remove("hide")
                    e.classList.add("show")
                    // 设置高斯模糊
                    // page.style.filter = "blur(3px)"
                } else {
                    e.classList.remove("show")
                    e.classList.add("hide")
                    // 恢复高斯模糊
                    // page.style.filter = "initial"
                }

                this.status = v
            },
            get: () => {
                return this.status
            },
        })

        COMP_MODAL_WINDOWS.push(this.winow_config)
    },

    mounted() {
        // 移动到 body 层级，不受父组件样式影响
        document.body.appendChild(this.$el)
        // 观察组件
        this.observer = window.MutationObserver
            ? new window.MutationObserver(mutationsList => {
                  for (let mutation of mutationsList) {
                      if (mutation.type === "childList" && this.isDisplay() && this.loadPool <= 0) {
                          this.checkLoad(mutation.target)
                      }
                  }
              })
            : null
        // 开始观察
        this.observer?.observe(this.$refs.content, {
            childList: true,
        })
        // 持续显示，加载完毕即显示
        this.continued && this.onDisplay()
    },

    methods: {
        /**
         * 判断当前窗口是否显示中
         */
        isDisplay() {
            return this.winow_config.status
        },

        /** 显示 */
        display() {
            for (let i = 0, ws = COMP_MODAL_WINDOWS; i < ws.length; i++) {
                let w = ws[i]

                if (w.status) {
                    w.status = false
                    // 记录将它关闭的窗口ID
                    w.close_id = this.winow_config.id
                }
            }
            this.winow_config.status = true
            this.$nextTick(() => {
                // 检验加载
                this.checkLoad(this.$refs.content)
            })
        },

        iframe(url) {
            this.iframeUrl = url
            this.display()
        },

        /** 关闭 */
        close() {
            // 关闭当前页面
            this.winow_config.status = false

            for (let i = 0, ws = COMP_MODAL_WINDOWS; i < ws.length; i++) {
                let w = ws[i]
                // 判断当前是否为将它关闭的ID，重新打开
                if (!w.status && w.close_id && w.close_id === this.winow_config.id) {
                    w.status = true
                    delete w.close_id
                }
            }

            // 触发关闭事件
            setTimeout(() => {
                this.$emit("on-close", {
                    tag: "Compmodal",
                })
            }, 300)
        },

        /** 关闭全部 */
        closeAll() {
            for (let i = 0, ws = COMP_MODAL_WINDOWS; i < ws.length; i++) {
                let w = ws[i]
                // 开启的窗口都关闭
                if (w.status) {
                    w.status = false
                    // 触发关闭事件
                    setTimeout(() => {
                        w.v.$emit("on-close", {
                            tag: "Compmodal",
                        })
                    }, 300)
                }
            }
        },

        onScroll(evt) {
            const t = evt.target
            if (t.scrollHeight - 10 <= t.scrollTop + t.clientHeight) {
                if (!this.bottoming) {
                    this.$emit("on-scroll-bottom")
                }
                this.bottoming = true
            } else {
                this.bottoming = false
            }
        },

        checkLoad(elem) {
            let l = elem?.getElementsByTagName?.("img")
            // 置空
            this.loadPool = 0

            if (l?.length > 0) {
                for (let i = 0; i < l.length; i++) {
                    var item = l[i]
                    // 已加载完毕或已监听加载
                    if (item.complete || item.onload) {
                        continue
                    }
                    // 添加到池
                    this.loadPool++
                    item.onload = () => {
                        // 清除
                        this.loadPool--
                        this.checkLoaded()
                    }
                    item.onerror = () => {
                        this.$Message.error("图片加载失败")
                    }
                }
            }

            this.checkLoaded()
        },

        /**
         * 检查是否加载完毕
         */
        checkLoaded() {
            clearTimeout(this.checkLoadedTimeout)

            if (this.loadPool <= 0) {
                return setTimeout(() => {
                    // 触发加载完成事件
                    this.$emit("on-load", {
                        tag: "CompModal",
                    })
                }, 100)
            }

            this.checkLoadedTimeout = setTimeout(() => {
                this.checkLoaded()
            }, 500)
        },
    },
}
</script>

<style lang="less">
.comp-modal-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;

    > .content-page {
        position: relative;
        min-width: 350px;
        display: flex;
        flex-direction: column;
        opacity: 0;
        visibility: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        }

        > .head-box {
            position: relative;
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #f3f3f3;
            flex-shrink: 0;
            display: flex;
            align-items: center;

            .title {
                font-weight: 500;
                color: #333;
                font-size: 16px;
                margin: 0 20px;
                flex-grow: 1;
                min-width: 100px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
                align-items: center;

                span {
                    display: none;

                    &:first-child {
                        display: initial;
                    }
                }
            }

            .head-operate {
                margin: 0 20px 0 10px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
            }

            .close-btn {
                margin: 10px 0;
                padding: 0 20px;
                font-size: 18px;
                color: #333;
                font-weight: bold;
                cursor: pointer;
                display: flex;
                align-items: center;
                border-left: 1px solid #f3f3f3;
                flex-shrink: 0;
            }
        }

        > .scroll-content {
            position: relative;
            width: 100%;
            flex-grow: 1;
        }

        > .bottom-operate {
            position: relative;
            flex-shrink: 0;
            border-top: 1px solid #f3f3f3;

            &:empty {
                display: none;
            }

            .operate-btns {
                padding: 6px 20px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                .operate-btn {
                    cursor: pointer;
                    margin: 5px 5px;
                    padding: 10px 20px;
                    background: #fff;
                    height: 40px;
                    flex: 1;
                    max-width: 250px;
                    line-height: 20px;
                    color: #333;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #e3e3e3;

                    &.highlight {
                        background: #2faaf7;
                        color: #fff;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }

    @keyframes show {
        0% {
            visibility: hidden;
            opacity: 0;
        }
        100% {
            visibility: initial;
            opacity: 1;
        }
    }

    @keyframes hide {
        0% {
            visibility: initial;
            opacity: 1;
        }
        99% {
            opacity: 0;
            visibility: initial;
        }
        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    &.show {
        visibility: initial;
        opacity: 1;

        > .content-page {
            animation: show 0.3s forwards;
        }
    }

    &.hide {
        visibility: hidden;
        opacity: 0;

        > .content-page {
            animation: hide 0.3s forwards;
        }
    }

    &.full > .content-page {
        width: 100%;
    }

    &.scroll > .content-page {
        max-height: 100%;

        > .scroll-content {
            overflow-x: auto;
            overflow-y: auto;
        }
    }
}

.ivu-message {
    z-index: 9999 !important;
}
</style>
